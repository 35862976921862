@tailwind base;
@tailwind components;
@tailwind utilities;

/* [data-reach-dialog-content] {
  @apply bg-gray-800 text-white py-2 px-4 border-none !important;
} */

[data-reach-dialog-overlay] {
  @apply relative z-[10000] !important;
}

.scroller[data-animated='true'] {
  -webkit-mask: linear-gradient(90deg, transparent, white 5%, white 95%, transparent);
  mask: linear-gradient(90deg, transparent, white 5%, white 95%, transparent);
  @apply overflow-hidden;
}

.scroller[data-animated='true'] .scroller__inner {
  @apply w-max animate-scroll flex-nowrap;
}
