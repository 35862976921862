nav.roadmap-desktop {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  color: #2f2f2f;
  z-index: 0;
  height: 6rem;
}
nav.roadmap-desktop::after {
  content: '';
  position: absolute;
  top: 1.7rem;
  left: 0;
  width: 100%;
  height: 3px;
  background-image: linear-gradient(to right, #ea5656 0%, #cd9f6a 55%, #4ae786 93.11%);
  pointer-events: none;
  z-index: -1000;
}

.roadmap-desktop .nav__track {
  position: relative;
  padding: 1.5rem 0 1.5rem 0;
  height: 6rem;
  z-index: -100;
}

.roadmap-desktop .nav__list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.roadmap-desktop .nav__link {
  position: relative;
  display: block;
  min-width: 3rem;
  padding: 2.25rem 0rem 0rem;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  z-index: 3000;
  transition: color 150ms;
}
.roadmap-desktop .nav__link:hover,
.roadmap-desktop .nav__link:focus {
  color: #9295d1;
}
.roadmap-desktop .nav__link::after {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  transform: translate3d(-50%, 0, 0);
  transform-origin: center center;
  z-index: 3100;
  border-style: solid;
  border-color: #ffffff;
  border-width: 3px;
}

.roadmap-desktop .nav__link.cex::after {
  background: #ea5656;
}

.roadmap-desktop .nav__link.pardex::after {
  background: #cd9f6a;
}

.roadmap-desktop .nav__link.dex::after {
  background: #4ae786;
}
.roadmap-desktop .nav__link span {
  display: block;
  transition: transform 200ms;
}

.roadmap-desktop .nav__link.cex {
  transform: translate(-50%, 0);
}

.roadmap-desktop .nav__link.pardex {
  transform: translate(0%, 0);
}

.roadmap-desktop .nav__link.dex {
  transform: translate(50%, 0);
}

.roadmap-desktop.upcoming .nav__track {
  padding: 1.5rem 0 1.5rem 0;
}
