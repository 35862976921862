html {
  --q1-after-bg-img: linear-gradient(90deg, #4986ea 12.55%, #5a68e6 100%);
  --q2-after-bg-img: linear-gradient(0deg, #000000 0, #000000 100%);
  --q3-after-bg-img: linear-gradient(0deg, #000000 0, #000000 100%);
  --q4-after-bg-img: linear-gradient(0deg, #000000 0, #000000 100%);
}

@import './stat-card.scss';
@import './flyer.scss';

@layer utilities {
  .list-interpunct > li:before {
    content: '・';
    float: left;
    margin: 0 0 0 -0.9em;
    width: 0.9em;
  }

  @media (min-width: 992px) {
    .list-interpunct > li:before {
      margin: 0 0 0 -1.5em;
      width: 1.5em;
    }
  }
}

// @layer base {
/* img[data-sizes="auto"] {
    display: block;
    width: 100%;
  } */

/*  html {
    overscroll-behavior: contain;

    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }
  @media (prefers-reduced-motion: reduce) {
    * {
      transition: none !important;
    }
  }*/

.dev-komodo-btn {
  border-radius: 20px;
  border: 1px solid #0fb;
  opacity: 0.8;
}

.developer-button {
  background: linear-gradient(90deg, #5a68e6 0%, #34acac 100%);
}

.reference-btn {
  background: linear-gradient(85deg, #12ffe3 2.3%, #5e50ff 92.67%, #bd4ef1 198.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.github-btn {
  background: linear-gradient(90deg, #000 0%, #04f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.developer-hero-title-bg {
  background: linear-gradient(90deg, #fff 30%, rgba(0, 255, 246, 0.68) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.developer-hero-title-bg.safari {
  background: none;
  color: rgba(0, 255, 246, 0.68);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 255, 246, 0.68);
}

.blog-reading-progress {
  width: 0%;
  height: 7px;
  left: 0px;
  background: #4986ea;
  position: fixed;
  top: 0px;
}

.wrapper {
  /* background: #0e101a;*/
  z-index: -35;
}

.horizantal-line {
  background-image: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
}

.text-shadow-era {
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.header-hash {
  display: none;
}

@media (min-width: 640px) {
  .header-hash {
    display: inline-flex;
    padding-right: 9px;
    margin-left: -38px;
  }
}

@media (min-width: 1280px) {
  .header-hash {
    display: inline-flex;
    padding-right: 9px;
    margin-left: 0px;
  }
}

@media (min-width: 1536px) {
  .header-hash {
    display: inline-flex;
    padding-right: 9px;
    margin-left: -38px;
  }
}

.filter-box {
  background: rgba(22, 25, 39, 0.85);
  border: 1px solid #2a2a2a;
  /* backdrop-filter: blur(26px);
     Note: backdrop-filter has minimal browser support */

  border-radius: 18px;
}

.clear-all {
  width: 57px;
  height: 20px;
  background: #9dd4f3;
  opacity: 0.8;
  border-radius: 18px;
}

.protocol-box {
  width: 293px;
  height: 200px;
  overflow-y: scroll;
  background: #121420;
  border-radius: 15px;
}

.custom-internal-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4e5f;
    //outline: 1px solid slategrey;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(147, 163, 177, 0.12);
    border-radius: 12px;
  }
}

.aio-wallet {
  width: 143.53px;
}

.aio-bridge {
  width: 157.8px;
}

.aio-dex {
  width: 134px;
}

@screen md {
  .aio-wallet {
    width: 143.53px;
  }

  .aio-bridge {
    width: 143.53px;
  }

  .aio-dex {
    width: 143.53px;
  }
}

@screen lg {
  .aio-wallet {
    width: 215.52px;
  }

  .aio-bridge {
    width: 257px;
  }

  .aio-dex {
    width: 201px;
  }
}

/*@keyframes cursor-blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

 .blinking-cursor {
    animation-name: cursor-blink;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-duration: 1s;
    //Basic styling
    display: inline-block;
    width: 8px;

    margin-left: 10px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(white, 0.3);
    background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  }

  .index-hero-atom-div {
    z-index: 7000;
  } */

.cryptos-500-img-div {
  display: grid;
  place-items: center;
  isolation: isolate;
}

.cryptos-500-img-div > * {
  grid-column: 1/-1;
  grid-row: 1/-1;
}

@screen lg {
  .social-icons-color circle {
    opacity: 0;
    transition: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }

  .social-icons-color .img-txt:hover circle {
    opacity: 1;
  }
}

// @screen lg {
//   .blog-card:hover {
//     box-shadow: 0 10px 30px 0 rgb(255 255 255 / 70%);
//     transform: translateY(-2px);
//   }
//  .blog-card {
// padding: 0.4em;
//transition: all 0.2s;
//   }
// }

// @screen 3xl {
//   .blog-card {
//     padding: 0.7em;
//   }
// }

.blog-tags {
  color: rgba(255, 255, 255, 0.7);
}

.blog-tags:hover {
  color: #ffffff;
}

.blog-cta-btn {
  border-color: transparent !important;
  transition:
    box-shadow 0.25s ease,
    transform 0.25s ease;
  cursor: pointer;
  user-select: none;
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.01em;
  border: none;
  border-radius: 7px;
  margin-right: 0.25rem !important;
  display: inline-block;
  line-height: 1.6;
  text-align: center;
  vertical-align: middle;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  text-decoration: none !important;
  box-sizing: border-box;
  color: #ffffff !important;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
}

.blog-cta-btn:hover {
  background: linear-gradient(180deg, #5a68e6, #4986ea);
  box-shadow:
    0 1rem 2.5rem rgba(22, 28, 45, 0.1),
    0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}

.footer-divider:before {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 90px;
  height: 1px;
  content: '\a0';
  background-color: #61657e;
  border: none;
}

.footer-sm-gray {
  color: rgba(213, 214, 217, 0.82);
}

.footer-lg-gray {
  color: rgba(255, 255, 255, 0.82);
}

.atomicdex-logo {
  width: 152px;
  height: auto;
}

@media (min-width: 475px) {
  .atomicdex-logo {
    width: 166.66px;
  }
}

@media (min-width: 1024px) {
  .atomicdex-logo {
    width: 150px;
  }
}

@media (min-width: 1536px) {
  .atomicdex-logo {
    width: 190px;
  }
}

.anchor-highlights {
  color: #6272fc;
  text-decoration: underline;
  font-weight: 500;
}

.gradient-underline {
  padding-bottom: 0px;
  position: relative;
  text-decoration: none;
}

.gradient-underline::after {
  background: linear-gradient(90deg, #8892eb 0%, #9dd4f3 93.11%);
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
}

/*.news-tag {
    background: linear-gradient(90deg, #6a4de3 0%, #2279f1 100%);
    background-clip: text;
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .news-tag.safari {
    background: none;
    color: #6a4de3;
    background-clip: text;
  -webkit-background-clip: text;
    -webkit-text-fill-color: #6a4de3;
  }*/

.news-tag {
  color: #2279f1;
}

.twitter-text {
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  padding-bottom: 1px;
  position: relative;
  text-decoration: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.twitter-text::after {
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
}

.twitter-text.safari {
  background: none;
  color: #5a68e6;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #5a68e6;
  text-decoration-line: underline;
}

.count-down-text-bg {
  background: linear-gradient(90deg, #8892eb 0%, #9dd4f3 93.11%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.count-down-text-bg.safari {
  background: none;
  color: #8892eb;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #8892eb;
}

.article-transparency {
  color: rgba(0, 0, 0, 0.25);
}

.show-more {
  border: double 3px transparent;
  border-radius: 9px;
  background-image: linear-gradient(#0e101a, #0e101a),
    radial-gradient(circle at top left, #5a68e6 12.55%, #4986ea 93.3%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.article-tag {
  border: double 2px transparent;
  border-radius: 18px;
  background-image: linear-gradient(#0e101a, #0e101a),
    radial-gradient(circle at top left, #8892eb 0%, #9dd4f3 93.11%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.full-list {
  border: double 2px transparent;
  background-origin: border-box;
  border-radius: 18px;
  background-clip: content-box, border-box;
  background-image: linear-gradient(#0e101a, #0e101a),
    radial-gradient(circle at top left, rgba(90, 104, 230) 12.55%, rgba(73, 134, 234) 93.3%);
}

.border-tag {
  border: 1px solid;
}

.border-gradient-tag {
  border-image-source: linear-gradient(90deg, #8892eb 0%, #9dd4f3 93.11%);
}

.komodo-knight-border {
  border-top: 1px solid;
  border-image-slice: 1;
  border-top-width: 1px;
}

.komodo-knight-border-gradient {
  border-image-source: linear-gradient(90deg, #5a68e6 4.26%, #4986ea 92.64%);
}

.komodo-knight-count-border {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-bottom-width: 1px;
  padding-bottom: 4px;
}

.komodo-knight-count-border-gradient {
  border-image-source: linear-gradient(90deg, #5a68e6 4.26%, #4986ea 92.64%);
}

.cta-btn {
  // width: 118px;
  // height: 38px;
  //background-image: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  background: linear-gradient(282deg, #31a5d4 -1.11%, #4369e5 119.32%);
  // border-radius: 7px;
}

.trades-completed {
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(73, 134, 234, 0.2);
}

.home-hero-btn {
  background: linear-gradient(90deg, #5a68e6 12.55%, rgba(73, 134, 234, 0.68) 93.3%);
}

.hero-btn {
  background: linear-gradient(85deg, #0eb4a1 2.3%, #5e50ff 92.67%, #bd4ef1 198.75%);
  //background: linear-gradient(85deg, #5e50ff 0%, #bd4ef1 133.21%);
  //background: linear-gradient(90deg, #5a68e6 0%, #3464ac 100%);
  // transition: all 0.1s ease-in-out;
}

// .hero-btn:hover {
//   background: linear-gradient(90deg, #3464ac 0%, #5a68e6 100%);
//   box-shadow: 0 4px 15px rgba(84, 104, 230, 0.3);
//   transform: translateY(-2px);
// }

.hero-download {
  width: 170px;
  height: 50px;
  background: #ffffff;
  border-radius: 18px;
}

.newsletter-ellipse-1 {
  width: calc(770px);
  height: calc(1725.23px);
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(40%, -45%) rotate(62.62deg);
}

.newsletter-ellipse-2 {
  width: calc(787.79px);
  height: calc(1126.67px);
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(106, 77, 227, 0.26) 0%,
    rgba(106, 77, 227, 0) 100%
  );
  transform: translate(-30%, -60%) rotate(42.43deg);
}

@media (min-width: 1024px) {
  .newsletter-ellipse-1 {
    width: calc(761.69px);
    height: calc(1518.44px);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(71%, -26%) rotate(62.62deg);
  }

  .newsletter-ellipse-2 {
    width: calc(828.06px);
    height: calc(1699.5px);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(30%, -56%) rotate(61.84deg);
  }
}

.webdex-ellipse {
  width: 90.15px;
  height: 201.75px;
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  // z-index: -24;

  mix-blend-mode: overlay;
  opacity: 0.48;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(85.84deg);
}

@media (min-width: 0px) {
  .download-hero-1 {
    width: 478.49px;
    height: 1070.8px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    z-index: -24;
    mix-blend-mode: overlay;
    transform: translate(0%, 0%) rotate(34.41deg);
  }

  .download-hero-2 {
    width: 488.96px;
    height: 699.29px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    z-index: -25;
    transform: translate(-40%, 0%) rotate(14.22deg);
  }
}

@media (min-width: 475px) {
  .download-hero-1 {
    width: calc(1359px * (475 * 1.5 / 1920));
    height: calc(3041.94px * (475 * 1.5 / 1920));
    transform: translate(0%, 0%) rotate(34.41deg);
  }

  .download-hero-2 {
    width: calc(1389.04px * (475 * 1.5 / 1920));
    height: calc(1986.55px * (475 * 1.5 / 1920));
    transform: translate(-30%, 0%) rotate(14.22deg);
  }
}

@media (min-width: 640px) {
  .download-hero-1 {
    width: calc(1359px * (640 * 1.5 / 1920));
    height: calc(3041.94px * (640 * 1.5 / 1920));
    transform: translate(0%, -10%) rotate(34.41deg);
  }

  .download-hero-2 {
    width: calc(1389.04px * (640 * 1.5 / 1920));
    height: calc(1986.55px * (640 * 1.5 / 1920));
    transform: translate(-30%, -10%) rotate(14.22deg);
  }
}

@media (min-width: 768px) {
  .download-hero-1 {
    width: calc(1359px * (768 * 1.5 / 1920));
    height: calc(3041.94px * (768 * 1.5 / 1920));
    transform: translate(20%, -21%) rotate(34.41deg);
  }

  .download-hero-2 {
    width: calc(1389.04px * (768 * 1.5 / 1920));
    height: calc(1986.55px * (768 * 1.5 / 1920));
    transform: translate(-10%, -10%) rotate(14.22deg);
  }
}

@media (min-width: 1024px) {
  .download-hero-1 {
    width: calc(1359px * (1024 * 1.5 / 1920));
    height: calc(3041.94px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(90%, -31%) rotate(34.41deg);
  }

  .download-hero-2 {
    width: calc(1389.04px * (1024 * 1.5 / 1920));
    height: calc(1986.55px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-62%, -25%) rotate(14.22deg);
  }
}

@media (min-width: 1280px) {
  .download-hero-1 {
    width: calc(1359px * (1280 * 1.2 / 1920));
    height: calc(3041.94px * (1280 * 1.2 / 1920));
  }

  .download-hero-2 {
    width: calc(1389.04px * (1280 * 1.2 / 1920));
    height: calc(1986.55px * (1280 * 1.2 / 1920));
  }
}

@media (min-width: 1536px) {
  .download-hero-1 {
    width: calc(1359px * (1536 / 1920));
    height: calc(3041.94px * (1536 / 1920));
  }

  .download-hero-2 {
    width: calc(1389.04px * (1536 / 1920));
    height: calc(1986.55px * (1536 / 1920));
  }
}

@media (min-width: 1920px) {
  .download-hero-1 {
    width: 1359.29px;
    height: 3041.94px;
  }

  .download-hero-2 {
    width: 1389.04px;
    height: 1986.55px;
  }
}

@media (min-width: 2000px) {
  .download-hero-1 {
    width: calc(1359px * (2000 / 1920));
    height: calc(3041.94px * (2000 / 1920));
  }

  .download-hero-2 {
    width: calc(1389.04px * (2000 / 1920));
    height: calc(1986.55px * (2000 / 1920));
  }
}

@media (min-width: 0px) {
  .header-ellipse-1 {
    width: 478.49px;
    height: 1070.8px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    z-index: -24;
    mix-blend-mode: overlay;
    transform: translate(0%, 0%) rotate(77.65deg);
  }

  .header-ellipse-2 {
    width: 488.96px;
    height: 699.29px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    z-index: -25;
    transform: translate(-40%, 0%) rotate(57.45deg);
  }
}

@media (min-width: 475px) {
  .header-ellipse-1 {
    width: calc(1359px * (475 * 1.5 / 1920));
    height: calc(3041.94px * (475 * 1.5 / 1920));
    transform: translate(0%, 0%) rotate(77.65deg);
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (475 * 1.5 / 1920));
    height: calc(1986.55px * (475 * 1.5 / 1920));
    transform: translate(-30%, 0%) rotate(57.45deg);
  }
}

@media (min-width: 640px) {
  .header-ellipse-1 {
    width: calc(1359px * (640 * 1.5 / 1920));
    height: calc(3041.94px * (640 * 1.5 / 1920));
    transform: translate(0%, -10%) rotate(77.65deg);
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (640 * 1.5 / 1920));
    height: calc(1986.55px * (640 * 1.5 / 1920));
    transform: translate(-30%, -10%) rotate(57.45deg);
  }
}

@media (min-width: 768px) {
  .header-ellipse-1 {
    width: calc(1359px * (768 * 1.5 / 1920));
    height: calc(3041.94px * (768 * 1.5 / 1920));
    transform: translate(20%, -21%) rotate(77.65deg);
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (768 * 1.5 / 1920));
    height: calc(1986.55px * (768 * 1.5 / 1920));
    transform: translate(-10%, -10%) rotate(57.45deg);
  }
}

@media (min-width: 1024px) {
  .header-ellipse-1 {
    width: calc(1359px * (1024 * 1.5 / 1920));
    height: calc(3041.94px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, -31%) rotate(77.65deg);
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (1024 * 1.5 / 1920));
    height: calc(1986.55px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-32%, -25%) rotate(57.45deg);
  }
}

@media (min-width: 1280px) {
  .header-ellipse-1 {
    width: calc(1359px * (1280 * 1.2 / 1920));
    height: calc(3041.94px * (1280 * 1.2 / 1920));
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (1280 * 1.2 / 1920));
    height: calc(1986.55px * (1280 * 1.2 / 1920));
  }
}

@media (min-width: 1536px) {
  .header-ellipse-1 {
    width: calc(1359px * (1536 / 1920));
    height: calc(3041.94px * (1536 / 1920));
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (1536 / 1920));
    height: calc(1986.55px * (1536 / 1920));
  }
}

@media (min-width: 1920px) {
  .header-ellipse-1 {
    width: 1359.29px;
    height: 3041.94px;
  }

  .header-ellipse-2 {
    width: 1389.04px;
    height: 1986.55px;
  }
}

@media (min-width: 2000px) {
  .header-ellipse-1 {
    width: calc(1359px * (2000 / 1920));
    height: calc(3041.94px * (2000 / 1920));
  }

  .header-ellipse-2 {
    width: calc(1389.04px * (2000 / 1920));
    height: calc(1986.55px * (2000 / 1920));
  }
}

.widest-ellipse-1 {
  width: calc(770px);
  height: calc(1725.23px);
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(8%, -35%) rotate(94.41deg);
}

@media (min-width: 768px) {
  .widest-ellipse-1 {
    transform: translate(48%, -35%) rotate(94.41deg);
  }
}

.widest-ellipse-2 {
  width: calc(787.79px);
  height: calc(1558.97px);
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(106, 77, 227, 0.26) 0%,
    rgba(106, 77, 227, 0) 100%
  );
  transform: translate(-69%, -48%) rotate(74.22deg);
}

@media (min-width: 1024px) {
  .widest-ellipse-1 {
    width: calc(770px);
    height: calc(1725.23px);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(80%, -26%) rotate(94.41deg);
  }

  .widest-ellipse-2 {
    width: calc(787.79px);
    height: calc(1558.97px);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-50%, -45%) rotate(74.22deg);
  }
}

@media (min-width: 1280px) {
  .widest-ellipse-2 {
    transform: translate(-23%, -45%) rotate(74.22deg);
  }
}

@media (min-width: 1536px) {
  .widest-ellipse-2 {
    transform: translate(-16%, -45%) rotate(74.22deg);
  }
}

.building-ellipse-1 {
  width: calc(1219px);
  height: calc(1219.23px);
  background: linear-gradient(180deg, rgba(42, 188, 241, 0.48) 0%, rgba(106, 77, 227, 0) 100%);
  transform: translate(-60%, -25%) rotate(150deg);
}

@media (min-width: 768px) {
  .building-ellipse-1 {
    width: calc(1219px);
    height: calc(1219.23px);
    background: linear-gradient(180deg, rgba(42, 188, 241, 0.48) 0%, rgba(106, 77, 227, 0) 100%);
    transform: translate(-30%, -30%) rotate(150deg);
  }
}

@media (min-width: 1024px) {
  .building-ellipse-1 {
    width: calc(1775.43px);
    height: calc(1429.3px);
    background: linear-gradient(180deg, rgba(42, 188, 241, 0.48) 0%, rgba(106, 77, 227, 0) 100%);
    transform: translate(-5%, -45%) rotate(150deg);
  }
}

.bottom-ellipse-1 {
  width: calc(576px);
  height: calc(740px);

  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(106, 77, 227, 0.26) 0%,
    rgba(106, 77, 227, 0) 100%
  );
  transform: translate(-60%, 890%) rotate(57.45deg);
}

.bottom-ellipse-2 {
  width: calc(635.61px);

  height: calc(651.23px);

  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(30%, 1000%) rotate(77.65deg);
}

@media (min-width: 475px) {
  .bottom-ellipse-1 {
    width: calc(576px);
    height: calc(740px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 890%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    width: calc(635.61px);

    height: calc(651.23px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(50%, 1000%) rotate(77.65deg);
  }
}

@media (min-width: 640px) {
  .bottom-ellipse-1 {
    width: calc(1389.04px * 0.6);
    height: calc(2440.12px * 0.6);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 490%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    width: calc(1359.29px * 0.6);

    height: calc(3041.94px * 0.6);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(50%, 370%) rotate(77.65deg);
  }
}

@media (min-width: 768px) {
  .bottom-ellipse-1 {
    width: calc(1389.04px * 0.8);
    height: calc(2440.12px * 0.8);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 360%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    width: calc(1359.29px * 0.8);

    height: calc(3041.94px * 0.8);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, 260%) rotate(77.65deg);
  }
}

@media (min-width: 1024px) {
  .bottom-ellipse-1 {
    width: calc(1389.04px);
    height: calc(2440.12px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 230%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    width: calc(1359.29px);

    height: calc(3041.94px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, 160%) rotate(77.65deg);
  }
}

@media (min-width: 1280px) {
  .bottom-ellipse-1 {
    transform: translate(-60%, 200%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    transform: translate(100%, 140%) rotate(77.65deg);
  }
}

@media (min-width: 1536px) {
  .bottom-ellipse-1 {
    transform: translate(-60%, 210%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    transform: translate(140%, 150%) rotate(77.65deg);
  }
}

@media (min-width: 1920px) {
  .bottom-ellipse-1 {
    transform: translate(-60%, 240%) rotate(57.45deg);
  }

  .bottom-ellipse-2 {
    transform: translate(140%, 180%) rotate(77.65deg);
  }
}

.blog-ellipse-1 {
  width: calc(1942px * (768 / 1920));
  height: calc(2778.29px * (768 / 1920));
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgb(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(-15%, -47%) rotate(85.3deg);
}

@screen xs {
  .blog-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(-5%, -47%) rotate(85.3deg);
  }
}

@screen sm {
  .blog-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(5%, -47%) rotate(85.3deg);
  }
}

@screen md {
  .blog-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(8%, -47%) rotate(85.3deg);
  }
}

@media (min-width: 1024px) {
  .blog-ellipse-1 {
    width: calc(1942px * (1024 * 1.5 / 1920));
    height: calc(2778.29px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(0%, -47%) rotate(85.3deg);
  }
}

@media (min-width: 1280px) {
  .blog-ellipse-1 {
    transform: translate(10%, -37%) rotate(85.3deg);
  }
}

@media (min-width: 1536px) {
  .blog-ellipse-1 {
    transform: translate(10%, -37%) rotate(85.3deg);
  }
}

@media (min-width: 1920px) {
  .blog-ellipse-1 {
    width: calc(1942px);
    height: calc(2778.29px);
    transform: translate(20%, -47%) rotate(85.3deg);
  }
}

.roadmap-header-section {
  margin: 5px 0 5px 0;
}

@screen md {
  .roadmap-header-section {
    margin: 105px 0 5px 0;
  }
}

@media (min-aspect-ratio: 2/1) {
  .roadmap-header-section {
    margin: 130px 0 0 0;
  }
}

@screen tiny {
  .roadmap-header-section {
    margin: 105px 0 5px 0;
  }
}

@media (min-width: 1024px) {
  .roadmap-header-section {
    margin: 110px 0 10px 0;
  }
}

@media (min-width: 1280px) {
  .roadmap-header-section {
    margin: 115px 0 15px 0;
  }
}

@media (min-width: 1536px) {
  .roadmap-header-section {
    margin: 120px 0 15px 0;
  }
}

@media (min-width: 1920px) {
  .roadmap-header-section {
    margin: 135px 0 35px 0;
  }
}

@screen tiny {
  .tiny-text-sm {
    font-size: 14px !important;
  }
}

@screen tiny {
  .tiny-q2-mt {
    margin-top: 44px !important;
  }
}

.trustless-gradient {
  background: linear-gradient(85deg, #12ffe3 2.33%, #5e50ff 62.54%, #bd4ef1 133.21%);
}

.main-gradient {
  background: linear-gradient(90deg, #0ba1ff 0%, #90f 75.5%);
}

.listed-crypto {
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
}

.widest {
  background: linear-gradient(90deg, #8892eb 0%, #9dd4f3 93.11%);
}

.stats {
  background: linear-gradient(90deg, #2abcf1 0%, rgba(106, 77, 227) 100%);
}

.in-progress {
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  //  mix-blend-mode: darken;
}

.blue-webdex {
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
}

.dex-gradient {
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
}

.listed-crypto,
.stats,
.widest,
.in-progress,
.blue-webdex,
.dex-gradient,
.trustless-gradient,
.main-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// .nav-hover:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   transform: scaleX(0);
//   height: 2px;
//   bottom: -2px;
//   left: 0;
//   background-color: rgb(107 114 128);
//   transform-origin: bottom right;
//   transition: transform 0.25s ease-out;
// }

// .nav-hover:hover:after {
//   transform: scaleX(1);
//   transform-origin: bottom left;
// }

@screen md {
  .roadmap-container {
    width: 400%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

.roadmap-card-header {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

@media (min-aspect-ratio: 2/1) {
  .roadmap-card-header {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .roadmap-card-header {
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media (min-width: 1280px) {
  .roadmap-card-header {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@screen tiny {
  .roadmap-card-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.roadmap-card {
  opacity: 0.9;
  backdrop-filter: blur(23px);
  /* Note: backdrop-filter has minimal browser support */
  border: 1px solid #29354b;
  border-radius: 10px;
}

@screen md {
  .roadmap-card {
    opacity: 1;
    backdrop-filter: blur(0px);
    border-radius: 18px;
  }
}

.tos-wrapper {
  //background: #ffffff;
  z-index: -35;
}

.tos {
  color: #ffffff;
  font-weight: 400;
}

.tos p {
  padding-top: 10px;
}

.tos h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (min-width: 1024px) {
  .tos h1 {
    font-size: 3.125rem;
  }
}

.tos h2 {
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (min-width: 1024px) {
  .tos h2 {
    font-size: 2.5rem;
  }
}

.tos h3 {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (min-width: 1024px) {
  .tos h3 {
    font-size: 1.563rem;
  }
}

// }
