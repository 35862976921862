.js-toc {
  position: relative;
  //left: 3em;
  //top: 5em;
  //padding: 1em;
  padding-left: 1em;
  padding-top: 1em;
  max-width: 16em;
  line-height: 2;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.js-toc ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.js-toc ul ul {
  padding-left: 1em;
}

@screen 2xl {
  .js-toc ul ul {
    padding-left: 2em;
  }
}

.js-toc li a {
  display: inline-block;
  //color: #aaa;
  margin-top: 2px;
  text-decoration: none;
  -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.js-toc li a:hover {
  text-decoration: underline;
  color: #5b69e6;
}

.js-toc li.visible > a {
  color: #5b69e6;
  font-weight: 700;
  -webkit-transform: translate(5px);
  -ms-transform: translate(5px);
  transform: translate(5px);
}

.js-toc-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.js-toc-marker path {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
