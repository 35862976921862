.index-ellipse-1 {
  width: calc(1942px * (768 / 1920));
  height: calc(2778.29px * (768 / 1920));
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgb(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(-15%, -47%) rotate(85.3deg);
}

@screen xs {
  .index-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(-5%, -47%) rotate(85.3deg);
  }
}

@screen sm {
  .index-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(5%, -47%) rotate(85.3deg);
  }
}

@screen md {
  .index-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(8%, -47%) rotate(85.3deg);
  }
}

@screen lg {
  .index-ellipse-1 {
    width: calc(3879px * (1280 / 1920));
    height: calc(2452px * (1280 / 1920));
    transform: translate(-19%, -42%);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
  }
}
@screen xl {
  .index-ellipse-1 {
    width: calc(3879px * (1280 / 1920));
    height: calc(2452px * (1280 / 1920));
    transform: translate(-19%, -42%);
  }
}

@screen 2xl {
  .index-ellipse-1 {
    width: calc(3879px * (1536 / 1920));
    height: calc(2452px * (1536 / 1920));
    transform: translate(-19%, -42%);
  }
}
@screen 3xl {
  .index-ellipse-1 {
    width: calc(3879px);
    height: calc(2452px);
    transform: translate(-25%, -42%);
  }
}

.index-ellipse-2 {
  width: calc(1359.29px * (768 / 1920));
  height: calc(3468.26px * (768 / 1920));
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgb(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: matrix(-0.21, 0.98, 0.98, 0.21, 0, 0);
  top: 3512px;
  left: -300px;
}

@screen xs {
  .index-ellipse-2 {
    top: 3512px;
    left: -300px;
  }
}

@screen sm {
  .index-ellipse-2 {
    top: 4212px;
    left: -300px;
  }
}

@screen md {
  .index-ellipse-2 {
    top: 4612px;
    left: -300px;
  }
}

@screen lg {
  .index-ellipse-2 {
    width: calc(1359.29px * (1024 * 1.5 / 1920));
    height: calc(3468.26px * (1024 * 1.5 / 1920));
    top: 4012px;
    left: -700px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: matrix(-0.21, 0.98, 0.98, 0.21, 0, 0);
  }
}
@screen xl {
  .index-ellipse-2 {
    top: 4212px;
    left: -600px;
  }
}

@screen 2xl {
  .index-ellipse-2 {
    top: 4212px;
    left: -600px;
  }
}
@screen 3xl {
  .index-ellipse-2 {
    width: calc(1359.29px);
    height: calc(3468.26px);
    top: 4012px;
    left: -700px;
  }
}

@screen xs {
  .index-ellipse-3 {
    width: calc(1359.29px * (768 / 1920));
    height: calc(3468.26px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: rotate(77.65deg);
    top: 2260px;
    left: -400px;
  }
}

@screen sm {
  .index-ellipse-3 {
    top: 2760px;
    left: -300px;
  }
}

@screen md {
  .index-ellipse-3 {
    top: 2912px;
    left: 200px;
  }
}

@screen lg {
  .index-ellipse-3 {
    width: calc(1359.29px * (1024 * 1.5 / 1920));
    height: calc(3468.26px * (1024 * 1.5 / 1920));
    top: 912px;
    left: 800px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: rotate(77.65deg);
  }
}
@screen xl {
  .index-ellipse-3 {
    top: 1012px;
    left: 1000px;
  }
}

@screen 2xl {
  .index-ellipse-3 {
    top: 1012px;
    left: 1200px;
  }
}
@screen 3xl {
  .index-ellipse-3 {
    width: calc(1359.29px);
    height: calc(3468.26px);
    top: 712px;
    left: 1400px;
  }
}

@screen lg {
  .index-ellipse-4 {
    width: calc(1389.04px * (1024 * 1.5 / 1920));
    height: calc(3468.26px * (1024 * 1.5 / 1920));
    top: 1412px;
    left: -1490px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: rotate(57.45deg);
  }
}
@screen xl {
  .index-ellipse-4 {
    top: 1512px;
    left: -1390px;
  }
}

@screen 2xl {
  .index-ellipse-4 {
    top: 1612px;
    left: -1190px;
  }
}
@screen 3xl {
  .index-ellipse-4 {
    width: calc(1389.04px);
    height: calc(3468.26px);
    top: 1412px;
    left: -1190px;
  }
}
