.desktop-bottom-ellipse-1 {
  width: calc(576px);
  height: calc(740px);

  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(106, 77, 227, 0.26) 0%,
    rgba(106, 77, 227, 0) 100%
  );
  transform: translate(-40%, 1580px) rotate(57.45deg);
}

.desktop-bottom-ellipse-2 {
  width: calc(635.61px);

  height: calc(651.23px);

  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(20%, 1580px) rotate(77.65deg);
}

@media (min-width: 475px) {
  .desktop-bottom-ellipse-1 {
    width: calc(576px);
    height: calc(740px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-40%, 230%) rotate(57.45deg);
  }

  .desktop-bottom-ellipse-2 {
    width: calc(635.61px);

    height: calc(651.23px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(40%, 235%) rotate(77.65deg);
  }
}
@media (min-width: 640px) {
  .desktop-bottom-ellipse-1 {
    width: calc(1389.04px * 0.6);
    height: calc(2440.12px * 0.6);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 110%) rotate(57.45deg);
  }

  .desktop-bottom-ellipse-2 {
    width: calc(1359.29px * 0.6);

    height: calc(3041.94px * 0.6);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(50%, 65%) rotate(77.65deg);
  }
}

@media (min-width: 768px) {
  .desktop-bottom-ellipse-1 {
    width: calc(1389.04px * 0.8);
    height: calc(2440.12px * 0.8);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 50%) rotate(57.45deg);
  }

  .desktop-bottom-ellipse-2 {
    width: calc(1359.29px * 0.8);

    height: calc(3041.94px * 0.8);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, 12%) rotate(77.65deg);
  }
}
@media (min-width: 1024px) {
  .desktop-bottom-ellipse-1 {
    width: calc(1741.04px);
    height: calc(2490.12px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 60%) rotate(42.43deg);
  }
  .desktop-bottom-ellipse-2 {
    width: calc(1704.31px);
    height: calc(3814.04px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, -4%) rotate(62.62deg);
  }
}
@media (min-width: 1280px) {
  .desktop-bottom-ellipse-1 {
    transform: translate(-60%, 60%) rotate(42.43deg);
  }
  .desktop-bottom-ellipse-2 {
    transform: translate(80%, -7%) rotate(62.62deg);
  }
}

@media (min-width: 1536px) {
  .desktop-bottom-ellipse-1 {
    transform: translate(-60%, 70%) rotate(42.43deg);
  }
  .desktop-bottom-ellipse-2 {
    transform: translate(90%, -3%) rotate(62.62deg);
  }
}

@media (min-width: 1920px) {
  .desktop-bottom-ellipse-1 {
    transform: translate(-50%, 80%) rotate(42.43deg);
  }
  .desktop-bottom-ellipse-2 {
    transform: translate(100%, 10%) rotate(62.62deg);
  }
}
