/*============================================================================*/
/* General card style */
/*============================================================================*/
.kg-card {
  margin: 0 0 var(--gap-lg);
  align-self: center;
  width: 100%;
  max-width: 100%;

  input {
    margin: 0;
  }
  img,
  div,
  a[data-fslightbox] {
    border-radius: inherit;
  }

  &.kg-width-wide {
    @media (--mq-md) {
      max-width: 80vw;
    }
    @media (--mq-lg) {
      max-width: 70vw;
    }
  }

  &.kg-width-full {
    @media (--mq-md) {
      max-width: 100vw;
      border-radius: 0;
    }
  }
}

/*============================================================================*/
/* Embed card */
/*============================================================================*/
.kg-embed-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 var(--gap-lg);
  min-width: 100%;
  max-width: 100%;

  .fluid-width-video-wrapper {
    margin: 0;
  }

  iframe {
    max-width: 100%;
  }
}

/*============================================================================*/
/* Image & Gallery card */
/*============================================================================*/
.kg-image-card,
.kg-gallery-card {
  max-width: 100vw;

  @media (--mq-sm) {
    max-width: 100%;
    border-radius: var(--global-radius);
  }
}

.kg-image-card,
.kg-gallery-image {
  position: relative;

  @media (--mq-sm) {
    max-width: 100%;
  }

  @mixin attention {
    .image-link {
      opacity: 1;
      visibility: visible;
    }
  }

  .image-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--color-bg-alpha-75);
    color: var(--color-white);
    z-index: 5;
    border: none !important;
    right: var(--gap);
    top: var(--gap);
    opacity: 0;
    visibility: hidden;
    transition: all var(--trans-default);

    @mixin attention {
      background-color: var(--color-bg);
    }

    .icon {
      color: var(--color-text);
    }
  }
}

.kg-image-card img,
.kg-gallery-card .kg-gallery-container {
  max-width: 100%;
  margin-bottom: 0;
  display: block;
  margin: 0 auto;
  height: auto;
}

.kg-gallery-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap);
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
  margin: var(--global-gallery-gap, 1em) 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 var(--global-gallery-gap, 1em);
}

/*============================================================================*/
/* Bookmark card */
/*============================================================================*/
.kg-bookmark-card {
  background-color: var(--color-bg);
  width: 100%;
  margin: 0;
  margin-bottom: var(--gap-lg);

  @mixin attention {
    .kg-bookmark-title {
      color: var(--color-primary);
    }
  }

  .kg-bookmark-container {
    border-radius: inherit !important;
    border: none !important;
  }
  .kg-bookmark-title {
    font-size: 1.2rem;
  }
  .kg-bookmark-description {
    font-size: 1rem;
  }
  .kg-bookmark-metadata {
    font-size: 0.9rem;
  }
}

// .kg-card + .kg-bookmark-card {
//   margin-top: 0;
// }

.kg-bookmark-container {
  display: flex;
  text-decoration: none;
  min-height: 148px;
  box-shadow: 0 0 0 1px var(--color-border);
  border-radius: inherit;
  flex-direction: column-reverse;

  @media (--mq-sm) {
    flex-direction: row;
  }
}

// .kg-bookmark-content {
//   display: flex;
//   flex-direction: column;
//   flex-grow: 1;
//   align-items: flex-start;
//   justify-content: flex-start;
//   padding: 20px;
// }

// .kg-bookmark-title {
//   font-weight: var(--font-weight-bold);
//   margin-bottom: var(--gap-sm);
// }

// .kg-bookmark-description {
//   overflow-y: hidden;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   font-size: 0.9rem;
//   margin-bottom: var(--gap);
//   display: -webkit-box;
//   color: var(--color-text-acc-3);
// }

.kg-bookmark-thumbnail {
  position: relative;
  min-width: 33%;
  max-height: 100%;
  overflow: hidden;
  padding-top: 56.25%;

  @media (--mq-sm) {
    padding-top: 0;
  }
}

.kg-bookmark-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 var(--global-radius) var(--global-radius) 0;
  margin: 0;
}

// .kg-bookmark-metadata {
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
//   font-size: 0.9rem;
//   font-weight: var(--font-weight-medium);
//   color: var(--color-text-acc-2);
// }

// .kg-bookmark-icon {
//   width: calc(1.5 * var(--gap));
//   height: calc(1.5 * var(--gap));
//   margin: 0;
//   margin-right: var(--gap-sm);
// }

// .kg-bookmark-author:after {
//   content: "•";
//   margin: 0 var(--gap-sm);
// }

.kg-bookmark-publisher {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
}

/*============================================================================*/
/* Code card */
/*============================================================================*/
.kg-code-card {
  max-width: 100%;
  width: 100%;
  margin: 0;
  margin-bottom: var(--gap-lg);

  pre {
    margin-bottom: var(--gap-sm);
  }
}

/*============================================================================*/
/* Callout card */
/*============================================================================*/
.kg-callout-card {
  width: 100%;
}
.kg-card.kg-callout-card + .kg-callout-card {
  margin-top: 0;
}

/*============================================================================*/
/* Toggle card */
/*============================================================================*/
.kg-toggle-card {
  width: 100%;
}
.kg-card.kg-toggle-card + .kg-toggle-card {
  margin-top: 0;
}

/*============================================================================*/
/* Blockquote alt card */
/*============================================================================*/
.kg-blockquote-alt {
  font-weight: var(--font-weight-semi-bold);
  border: none;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  border-radius: 0;
}

blockquote.kg-blockquote-alt {
  padding: var(--gap);

  @media (--mq-md) {
    padding: var(--gap-lg);
  }
}

/*============================================================================*/
/* Video card */
/*============================================================================*/
.kg-video-card {
  max-width: 100vw;

  @media (--mq-sm) {
    max-width: 100%;
  }
}

/*============================================================================*/
/* File card */
/*============================================================================*/
.kg-file-card {
  width: 100%;
}

/*============================================================================*/
/* Header card */
/*============================================================================*/
.kg-header-card {
  max-width: 100vw;
}

/*============================================================================*/
/* avoid overflow if wide/full cards */
/*============================================================================*/
.content-wrap.no-overflow {
  @media (--mq-sm) {
    .kg-card,
    .kg-card.kg-width-wide,
    .kg-card.kg-width-full {
      max-width: 100%;
      border-radius: var(--global-radius);
    }

    .kg-video-card {
      border-radius: 0 !important;
    }
  }
}
