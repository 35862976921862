.downloads-ellipse-1 {
  width: calc(1942px * (768 / 1920));
  height: calc(2778.29px * (768 / 1920));
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgb(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(-15%, -17%) rotate(85.3deg);
}

@screen xs {
  .downloads-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(-5%, -7%) rotate(85.3deg);
  }
}

@screen sm {
  .downloads-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(5%, -7%) rotate(85.3deg);
  }
}

@screen md {
  .downloads-ellipse-1 {
    width: calc(1942px * (768 / 1920));
    height: calc(2778.29px * (768 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(8%, -27%) rotate(85.3deg);
  }
}

@media (min-width: 1024px) {
  .downloads-ellipse-1 {
    width: calc(1942px * (1024 * 1.5 / 1920));
    height: calc(2778.29px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(0%, -47%) rotate(85.3deg);
  }
}
@media (min-width: 1280px) {
  .downloads-ellipse-1 {
    transform: translate(10%, -37%) rotate(85.3deg);
  }
}

@media (min-width: 1536px) {
  .downloads-ellipse-1 {
    transform: translate(10%, -37%) rotate(85.3deg);
  }
}
@media (min-width: 1920px) {
  .downloads-ellipse-1 {
    width: calc(1942px);
    height: calc(2778.29px);
    transform: translate(20%, -47%) rotate(85.3deg);
  }
}
