@media (min-width: 0px) {
  .mobile-header-ellipse-1 {
    width: 478.49px;
    height: 1070.8px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    z-index: -24;
    mix-blend-mode: overlay;
    transform: translate(0%, 0%) rotate(77.65deg);
  }
  .mobile-header-ellipse-2 {
    width: 488.96px;
    height: 699.29px;
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    z-index: -25;
    transform: translate(-40%, 0%) rotate(57.45deg);
  }
}
@media (min-width: 475px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (475 * 1.5 / 1920));
    height: calc(3041.94px * (475 * 1.5 / 1920));
    transform: translate(0%, 0%) rotate(77.65deg);
  }

  .mobile-header-ellipse-2 {
    width: calc(2480px * (475 * 1.5 / 1920));
    height: calc(1986.55px * (475 * 1.5 / 1920));
    transform: translate(-30%, 0%) rotate(57.45deg);
  }
}

@media (min-width: 640px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (640 * 1.5 / 1920));
    height: calc(3041.94px * (640 * 1.5 / 1920));
    transform: translate(0%, -10%) rotate(77.65deg);
  }
  .mobile-header-ellipse-2 {
    width: calc(2480px * (640 * 1.5 / 1920));
    height: calc(1986.55px * (640 * 1.5 / 1920));
    transform: translate(-30%, -10%) rotate(57.45deg);
  }
}
@media (min-width: 768px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (768 * 1.5 / 1920));
    height: calc(3041.94px * (768 * 1.5 / 1920));
    transform: translate(20%, -21%) rotate(77.65deg);
  }

  .mobile-header-ellipse-2 {
    width: calc(2480px * (768 * 1.5 / 1920));
    height: calc(1986.55px * (768 * 1.5 / 1920));
    transform: translate(-10%, -10%) rotate(57.45deg);
  }
}
@media (min-width: 1024px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (1024 * 1.5 / 1920));
    height: calc(3041.94px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgb(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(28%, -39%) rotate(34.41deg);
  }
  .mobile-header-ellipse-2 {
    width: calc(2480px * (1024 * 1.5 / 1920));
    height: calc(1986.55px * (1024 * 1.5 / 1920));
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-72%, 5%) rotate(14.22deg);
  }
}

@media (min-width: 1280px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (1280 * 1.2 / 1920));
    height: calc(3041.94px * (1280 * 1.2 / 1920));
    transform: translate(58%, -39%) rotate(34.41deg);
  }
  .mobile-header-ellipse-2 {
    width: calc(2480px * (1280 * 1.2 / 1920));
    height: calc(1986.55px * (1280 * 1.2 / 1920));
  }
}
@media (min-width: 1536px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (1536 / 1920));
    height: calc(3041.94px * (1536 / 1920));
    transform: translate(78%, -39%) rotate(34.41deg);
  }
  .mobile-header-ellipse-2 {
    width: calc(2480px * (1536 / 1920));
    height: calc(1986.55px * (1536 / 1920));
    transform: translate(-62%, 5%) rotate(14.22deg);
  }
}
@media (min-width: 1920px) {
  .mobile-header-ellipse-1 {
    width: 1359.29px;
    height: 3041.94px;
  }
  .mobile-header-ellipse-2 {
    width: 2480px;
    height: 1986.55px;
    transform: translate(-42%, 8%) rotate(14.22deg);
  }
}
@media (min-width: 2000px) {
  .mobile-header-ellipse-1 {
    width: calc(1359px * (2000 / 1920));
    height: calc(3041.94px * (2000 / 1920));
  }
  .mobile-header-ellipse-2 {
    width: calc(2480px * (2000 / 1920));
    height: calc(1986.55px * (2000 / 1920));
  }
}

@media (min-width: 2100px) {
  .mobile-header-ellipse-1 {
    transform: translate(88%, -39%) rotate(34.41deg);
  }
}
@media (min-width: 3000px) {
  .mobile-header-ellipse-1 {
    transform: translate(115%, -39%) rotate(34.41deg);
  }
}

.mobile-bottom-ellipse-1 {
  width: calc(576px);
  height: calc(740px);

  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(106, 77, 227, 0.26) 0%,
    rgba(106, 77, 227, 0) 100%
  );
  transform: translate(-60%, 2080px) rotate(57.45deg);
}

.mobile-bottom-ellipse-2 {
  width: calc(635.61px);

  height: calc(651.23px);

  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(30%, 2080px) rotate(77.65deg);
}

@media (min-width: 475px) {
  .mobile-bottom-ellipse-1 {
    width: calc(576px);
    height: calc(740px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-50%, 240%) rotate(57.45deg);
  }

  .mobile-bottom-ellipse-2 {
    width: calc(635.61px);

    height: calc(651.23px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(40%, 265%) rotate(77.65deg);
  }
}
@media (min-width: 640px) {
  .mobile-bottom-ellipse-1 {
    width: calc(1389.04px * 0.6);
    height: calc(2440.12px * 0.6);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 130%) rotate(57.45deg);
  }

  .mobile-bottom-ellipse-2 {
    width: calc(1359.29px * 0.6);

    height: calc(3041.94px * 0.6);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(50%, 75%) rotate(77.65deg);
  }
}

@media (min-width: 768px) {
  .mobile-bottom-ellipse-1 {
    width: calc(1389.04px * 0.8);
    height: calc(2440.12px * 0.8);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 50%) rotate(57.45deg);
  }

  .mobile-bottom-ellipse-2 {
    width: calc(1359.29px * 0.8);

    height: calc(3041.94px * 0.8);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, 6%) rotate(77.65deg);
  }
}
@media (min-width: 1024px) {
  .mobile-bottom-ellipse-1 {
    width: calc(1741.04px);
    height: calc(2490.12px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(-60%, 60%) rotate(42.43deg);
  }
  .mobile-bottom-ellipse-2 {
    width: calc(1704.31px);
    height: calc(3814.04px);

    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(70%, 4%) rotate(62.62deg);
  }
}
@media (min-width: 1280px) {
  .mobile-bottom-ellipse-1 {
    transform: translate(-60%, 60%) rotate(42.43deg);
  }
  .mobile-bottom-ellipse-2 {
    transform: translate(80%, 7%) rotate(62.62deg);
  }
}

@media (min-width: 1536px) {
  .mobile-bottom-ellipse-1 {
    transform: translate(-60%, 70%) rotate(42.43deg);
  }
  .mobile-bottom-ellipse-2 {
    transform: translate(90%, 8%) rotate(62.62deg);
  }
}

@media (min-width: 1920px) {
  .mobile-bottom-ellipse-1 {
    transform: translate(-50%, 80%) rotate(42.43deg);
  }
  .mobile-bottom-ellipse-2 {
    transform: translate(100%, 10%) rotate(62.62deg);
  }
}

.supported-protocols-ellipse-1 {
  width: calc(761.69px);
  height: calc(956.63px);
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(42, 188, 241, 0.3) 0%,
    rgba(42, 188, 241, 0) 100%
  );
  transform: translate(28%, -5%) rotate(62.62deg);
}
.supported-protocols-ellipse-2 {
  width: calc(828.06px);
  height: calc(1107.03px);
  background: radial-gradient(
    circle closest-side at 50% 50%,
    rgba(106, 77, 227, 0.26) 0%,
    rgba(106, 77, 227, 0) 100%
  );
  transform: translate(32%, -30%) rotate(42.43deg);
}

@media (min-width: 1024px) {
  .supported-protocols-ellipse-1 {
    width: calc(761.69px);
    height: calc(956.63px);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(42, 188, 241, 0.3) 0%,
      rgba(42, 188, 241, 0) 100%
    );
    transform: translate(81%, -9%) rotate(62.62deg);
  }

  .supported-protocols-ellipse-2 {
    width: calc(828.06px);
    height: calc(1107.03px);
    background: radial-gradient(
      circle closest-side at 50% 50%,
      rgba(106, 77, 227, 0.26) 0%,
      rgba(106, 77, 227, 0) 100%
    );
    transform: translate(32%, -35%) rotate(42.43deg);
  }
}
