.stat-card {
  position: relative;
  overflow: hidden;
}

.stat-card:hover::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 80%;
  background: linear-gradient(to right, #051019 15%, #1377d3 40%);
  z-index: -1;
}

.stat-card.animated::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 80%;
  background: linear-gradient(to right, #051019 15%, #1377d3 40%);
  z-index: -1;
}

.stat-card::after {
  content: '';
  position: absolute;
  inset: 2px;
  background: #0e1e2b;
  border-radius: 14px;
  z-index: -1;
}

.stat-card.pos-1.animated::before {
  animation: animate-1 2s linear infinite;
}

.stat-card.pos-2.animated::before {
  animation: animate-2 2s linear infinite;
}

.stat-card.pos-3.animated::before {
  animation: animate-3 2s linear infinite;
}

.stat-card.pos-4.animated::before {
  animation: animate-4 2s linear infinite;
}

.stat-card.pos-1:hover::before {
  animation: animate-1 3s linear infinite;
}

.stat-card.pos-2:hover::before {
  animation: animate-2 3s linear infinite;
}

.stat-card.pos-3:hover::before {
  animation: animate-3 3s linear infinite;
}

.stat-card.pos-4:hover::before {
  animation: animate-4 3s linear infinite;
}

@keyframes animate-1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animate-2 {
  from {
    transform: rotate(70deg);
  }
  to {
    transform: rotate(410deg);
  }
}

@keyframes animate-3 {
  from {
    transform: rotate(130deg);
  }
  to {
    transform: rotate(490deg);
  }
}

@keyframes animate-4 {
  from {
    transform: rotate(190deg);
  }
  to {
    transform: rotate(550deg);
  }
}
