/* @import "./docsearch.css"; */
@import './decentralized-spectrum.scss';
@import './blog.scss';
@import './index-ellipses.scss';
@import './tailwind-init.css';
@import './lp.scss';
@import './lp-ellipse.scss';

// From atomicdex.io site
// @import "./aos.css";
// @import "./roadmap-mobile.scss";
// @import "./roadmap-desktop.scss";
@import './mobile.scss';
@import './desktop.scss';
@import './magic-toc.scss';
@import './ghost-blog.scss';
@import './joben-ghost.scss';
// @import "./lite-yt-embed.scss";
@import './cookie-consent.scss';
@import './index-ellipses.scss';
@import './table-supported-assets.scss';
@import './downloads.scss';
//

:root {
  --shiki-color-text: theme('colors.white');
  --shiki-token-constant: theme('colors.emerald.300');
  --shiki-token-string: theme('colors.emerald.300');
  --shiki-token-comment: theme('colors.zinc.500');
  --shiki-token-keyword: theme('colors.sky.300');
  --shiki-token-parameter: theme('colors.pink.300');
  --shiki-token-function: theme('colors.violet.300');
  --shiki-token-string-expression: theme('colors.emerald.300');
  --shiki-token-punctuation: theme('colors.zinc.200');
}

@layer base {
  :root {
    --color-primary: 255 115 179;
    --color-secondary: 111 114 185;
  }
}

// .dancing-bg {
//   border: none;
//   outline: none;
// }

// .dancing-bg:before {
//   content: '';
//   background: linear-gradient(
//     45deg,
//     #14f1d7,
//     #5e50ff,
//     #bd4ef1,
//     #14f1d7,
//     #5e50ff,
//     #bd4ef1,
//     #14f1d7,
//     #5e50ff,
//     #bd4ef1,
//     #14f1d7
//   );
//   position: absolute;
//   top: -2px;
//   left: -2px;
//   background-size: 400%;
//   z-index: -1;
//   filter: blur(10px);
//   width: calc(100% + 8px);
//   height: calc(100% + 8px);
//   animation: glowing 20s linear infinite;
//   opacity: 1;
//   //transition: opacity 0.3s ease-in-out;
// }

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.nav-cta-btn {
  background-image: linear-gradient(90deg, #0a9288 12.55%, #087f77 93.3%);
  border-radius: 7px;
  transition: all 0.3s ease-in-out;
}

.nav-cta-btn:hover {
  background-image: linear-gradient(90deg, #087f77 12.55%, #0a9288 93.3%);
}

/* to nullify tailwind typography's centering effect from  
@media (min-width: 1024px)
.prose :where(.prose > *):not(:where([class~="not-prose"] *)) {
    max-width: none;
    margin-left: calc(50% - min(50%, 33rem));
    margin-right: calc(50% - min(50%, 33rem));*/
@media (min-width: 1024px) {
  .prose :where(.prose > *):not(:where([class~='not-prose'] *)) {
    margin-left: auto;
    margin-right: auto;
  }
}

* {
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

html {
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  background: #76a38f;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #026782;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: $primaryColor; */
}

.noScroll -webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.blog-tags {
  color: rgba(255, 255, 255, 0.7);
}

.blog-tags:hover {
  color: #ffffff;
}

.article-tag {
  border: double 2px transparent;
  border-radius: 18px;
  background-image: linear-gradient(#0e101a, #0e101a),
    radial-gradient(circle at top left, #8892eb 0%, #9dd4f3 93.11%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.atomicdexButton {
  border-color: transparent !important;
  transition:
    box-shadow 0.25s ease,
    transform 0.25s ease;
  cursor: pointer;
  user-select: none;
  background: linear-gradient(90deg, #5a68e6 12.55%, #4986ea 93.3%);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.01em;
  border: none;
  border-radius: 7px;
  margin-right: 0.25rem !important;
  display: inline-block;
  line-height: 1.6;
  text-align: center;
  vertical-align: middle;
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  text-decoration: none !important;
  box-sizing: border-box;
  color: #ffffff !important;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
}
.atomicdexButton:hover {
  background: linear-gradient(180deg, #5a68e6, #4986ea);
  box-shadow:
    0 1rem 2.5rem rgba(22, 28, 45, 0.1),
    0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1) !important;
  transform: translate3d(0, -3px, 0);
}

.news-tag {
  color: #2279f1;
}

.komodo-knight-border {
  border-top: 1px solid;
  border-image-slice: 1;
  border-top-width: 1px;
}

.komodo-knight-border-gradient {
  border-image-source: linear-gradient(90deg, #5a68e6 4.26%, #4986ea 92.64%);
}

.komodo-knight-count-border {
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-bottom-width: 1px;
  padding-bottom: 4px;
}

.komodo-knight-count-border-gradient {
  border-image-source: linear-gradient(90deg, #5a68e6 4.26%, #4986ea 92.64%);
}

.article-tag {
  border: double 2px transparent;
  border-radius: 18px;
  background-image: linear-gradient(#0e101a, #0e101a),
    radial-gradient(circle at top left, #8892eb 0%, #9dd4f3 93.11%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.blog-header-hash {
  display: none;
}

@media (min-width: 640px) {
  .blog-header-hash {
    display: inline-flex;
    padding-right: 9px;
    margin-left: -38px;
  }
}
@media (min-width: 1280px) {
  .blog-header-hash {
    display: inline-flex;
    padding-right: 9px;
    margin-left: 0px;
  }
}
@media (min-width: 1536px) {
  .blog-header-hash {
    display: inline-flex;
    padding-right: 9px;
    margin-left: -38px;
  }
}

.border-right-sidebar {
  /* background-color: #192336; */
  border-radius: 1em;
  /* border: solid 1px #363636; */
}

.nav-border-not-scrolled::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: rgb(161 161 170);
  transition: all ease-in-out 0.2s;
}

@media (min-width: 1024px) {
  .nav-border-not-scrolled::after {
    content: '';
    position: absolute;
    width: 10%;
    height: 2px;
    left: 45%;
    bottom: 0;
    background-color: #60a5fa;
    transition: all ease-in-out 0.2s;
  }
}

.nav-border-animated-scrolled::after {
  width: 100%;
  left: 0;
  content: '';
  position: absolute;
  height: 2px;
  bottom: 0;
  background-color: #60a5fa;
  transition: all ease-in-out 0.2s;
}

.keyboard-button {
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #b0b0b0;
  box-shadow:
    0 2px 3px rgba(0, 0, 0, 0.1) inset,
    0 -1px 0 rgba(255, 255, 255, 0.5) inset;
  padding: 2px 3px;
  margin: 5px;
  border-radius: 3px;
  font-family: 'Courier New', monospace;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: 0.1s all ease-in-out;
}

.copy-snippet {
  cursor: url(../../public/copyIcon.png), auto;
}
.copy-snippet:hover {
  background-color: #333;
}
.copy-snippet:active {
  cursor: url(../../public/check.png), auto;
}

.back-to-homepage {
  border-color: rgba(73, 134, 234, 0.68);
}

/* TOS */
.tos-wrapper {
  /* //background: #ffffff; */
  z-index: -35;
}

.tos {
  color: #ffffff;
  font-weight: 400;
}

.tos p {
  padding-top: 10px;
}

.tos h1 {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (min-width: 1024px) {
  .tos h1 {
    font-size: 3.125rem;
  }
}

.tos h2 {
  color: #ffffff;
  font-size: 1.875rem;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (min-width: 1024px) {
  .tos h2 {
    font-size: 2.5rem;
  }
}

.tos h3 {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 4px;
}

@media (min-width: 1024px) {
  .tos h3 {
    font-size: 1.563rem;
  }
}

/* */
.breadcrumb-nav {
  border: 1px solid rgb(8, 8, 28);
  font-size: 0.85em;
}

.breadcrumb-nav ol {
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.breadcrumb-nav li {
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.breadcrumb-nav li:not(:last-child)::after {
  border-bottom: 0.75ch solid transparent;
  border-left: 0.75ch solid #fff;
  border-top: 0.75ch solid transparent;
  content: '';
  display: inline-block;
  margin-left: 1rem;
  /* margin-right: 0.5rem;  */
}

.breadcrumb-nav a {
  display: inline-block;
  font-weight: normal;
}

.breadcrumb-nav a:focus,
.breadcrumb-nav a[href]:hover {
  outline: 4px solid;
  outline-offset: 2px;
  text-decoration: none;
}

.breadcrumb-nav [aria-current] {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

.breadcrumb-nav [aria-current][href]:hover,
.breadcrumb-nav [aria-current]:focus {
  outline: 2px solid;
}

.fake-code-editor .code-pre .line:before {
  content: '$';
  color: #999999;
  border-right: 1px solid #999999;
  margin-right: 12px;
  padding: 34px 12px;
  padding-top: 9px;
}

.fake-code-editor .code {
  font-size: 1em;
  animation: fadeIn 0.5s;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fake-code-editor pre {
  background-color: transparent !important;
}

.fake-code-editor .line {
  display: block;
  margin-bottom: 5px;
  text-indent: -46px;
  white-space: pre-wrap;
  padding-left: 1.5em;
  margin-left: 23px;
}
