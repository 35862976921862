// Common styles for all ellipses
@mixin ellipse-common {
  position: absolute;
  // background: radial-gradient(
  //   ellipse at 62.23% 49.94%,
  //   rgba(0, 255, 212, 0.5) 29.5%,
  //   rgba(117, 30, 228, 0.5) 100%
  // );
  background: radial-gradient(57.69% 63.68% at 76.22% 52.45%, #751ee4 0%, #00ffd4 100%);
  filter: blur(125px);
  -webkit-filter: blur(125px);
  transform: translate3d(0, 0, 0);
  z-index: -11;
}

.lp-ellipse-223 {
  @include ellipse-common;
  border-radius: 359px;
  opacity: 0.6;
}

.lp-ellipse-224 {
  @include ellipse-common;
  border-radius: 515px;
  opacity: 0.6;
}

.lp-ellipse-232 {
  @include ellipse-common;
  border-radius: 733px;
  opacity: 0.31;
}

.lp-ellipse-233 {
  @include ellipse-common;
  border-radius: 783px;
  opacity: 0.55;
}

.lp-ellipse-234 {
  @include ellipse-common;
  border-radius: 910px;
  opacity: 0.65;
}

.lp-ellipse-236 {
  @include ellipse-common;
  border-radius: 910px;
  opacity: 0.36;
}

.lp-ellipse-237 {
  @include ellipse-common;
  border-radius: 910px;
  opacity: 0.29;
}

.lp-ellipse-238 {
  @include ellipse-common;
  border-radius: 910px;
  opacity: 0.36;
}

.lp-ellipse-239 {
  @include ellipse-common;
  border-radius: 910px;
  opacity: 0.32;
}

.lp-ellipse-240 {
  @include ellipse-common;
  border-radius: 814px;
  opacity: 0.27;
}
