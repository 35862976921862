.solid-block-shadow {
  z-index: 0;
  overflow: hidden;
  margin-bottom: 16px;
  border: 2px solid rgb(0, 0, 0);
  transition: none;
  box-shadow: rgb(180, 163, 163) 6px 6px 0px 0px;
  color: rgb(0, 0, 0);
  background: linear-gradient(270deg, #8bcbd9, #44b7a3);
  //width: calc(100% - 12px);
  border-radius: 30px;
}

.solid-block-shadow:hover {
  transform: translate(4px, 4px);
  box-shadow: rgb(0, 0, 0) 4px 4px 0px 0px;
}

@media print {
  /* Ensure the page doesn't have any margins */
  @page {
    margin: 0;
  }

  /* Reset body margins and set background to white */
  body {
    margin: 0;
    background: white;
  }

  /* Make sure the main container takes up the full page */
  .container {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
  }

  /* Ensure images don't stretch */
  img {
    max-width: 100%;
    height: auto !important;
    width: 100% !important;
  }

  /* Specific rule for the hero image */
  #links img {
    max-height: 100vh;
    object-fit: contain;
  }

  /* Ensure link buttons don't break across pages */
  #links a {
    page-break-inside: avoid;
  }

  /* Hide any elements not needed in print */
  .no-print {
    display: none !important;
  }
}
